<template>
    <div class="dashboard" :style="`--var-element-color: ${ progressTracking.public_settings.element_color ? progressTracking.public_settings.element_color : '#2f7eed' };--var-element-text-color: ${ progressTracking.public_settings.element_text_color ? progressTracking.public_settings.element_text_color : '#2c3e50' };`">
        <div class="client_card">
            <div v-show="progressTracking.public_settings.display_header == 1" class="card_header pointer"  @click="handleSetting('hdrsetting')" ref="hdrsection" :style="`background: ${progressTracking.public_settings.header_bgcolor}; color: ${progressTracking.public_settings.header_textcolor};`">
                <nav>
                    <img class="logo_img" :src="progressTracking.public_settings.logo ? progressTracking.public_settings.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                    <a>
                        <h4>Dashboard</h4>
                    </a>
                </nav>
                <div class="user_box" @click="accountDrop = !accountDrop">
                    <img :src="progressTracking.public_settings.author ? progressTracking.public_settings.author : user.profile_pic" :alt="user.full_name">
                    <div class="user_info">
                        <h5 :style="`color: ${progressTracking.public_settings.header_textcolor};`">{{ user.full_name }} <i class="fas fa-caret-down" :class="`${accountDrop ? 'swing' : ''}`"></i></h5>
                    </div>
                    <ul class="account_drop text-inherit" :class="{ active : accountDrop }">
                        <li><i class="fas fa-home"></i> Primary Location</li>
                        <li><i class="far fa-user"></i> Profile Info</li>
                        <li><i class="far fa-file"></i> My Files</li>
                        <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                    </ul>
                </div>
            </div>
            <div class="card_body" :class="device != 'desk' ? ( device == 'tab' && progressTracking.public_settings.cover_type == 1 ? 'cover-image-md' : (progressTracking.public_settings.cover_type == 1 ? 'cover-image-sm' : '')) :'cover-image'" @click="handleSetting('cvrsetting')" ref="cvrsection" v-show="progressTracking.public_settings.display_cover && progressTracking.public_settings.has_small_cover">
                <picture v-if="(progressTracking.public_settings.cover_type == 2 || progressTracking.public_settings.cover_type == 3)">
                    <source v-if="progressTracking.public_settings.small_cover != null && progressTracking.public_settings.small_cover != '' && progressTracking.public_settings.small_cover != 'null'" :srcset="`${progressTracking.public_settings.small_cover}`" media="(max-width: 768px)">
                    <img v-if="device == 'desk'" :src="progressTracking.public_settings.cover ? progressTracking.public_settings.cover : require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner_img">
                    <img v-else :src="progressTracking.public_settings.small_cover ? progressTracking.public_settings.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                </picture>
                <span v-if="progressTracking.public_settings.cover_type == 1" class="overlay" :style="`background-color: ${progressTracking.public_settings.cover_color ? progressTracking.public_settings.cover_color : progressTracking.public_settings.overlay_color};`"></span>
                <span v-else-if="progressTracking.public_settings.cover_type == 2 && progressTracking.public_settings.has_cover_overlay" class="overlay" :style="`background-color: ${progressTracking.public_settings.overlay_color}; opacity: ${(progressTracking.public_settings.opac_overlay_color == 'transparent' || !progressTracking.public_settings.has_cover_overlay || progressTracking.public_settings.overlay_opacity == 0) ? '0': ((progressTracking.public_settings.overlay_opacity / 100 ))};`"></span>
                <span v-else-if="progressTracking.public_settings.cover_type == 3 && progressTracking.public_settings.has_cover_overlay" class="overlay" :style="`background-color: ${progressTracking.public_settings.overlay_color}; opacity: ${(progressTracking.public_settings.opac_overlay_color == 'transparent' || !progressTracking.public_settings.has_cover_overlay || progressTracking.public_settings.overlay_opacity == 0) ? '0': ((progressTracking.public_settings.overlay_opacity / 100 ))};`"></span>
                <div class="banner_content" v-if="device == 'desk'">
                    <h4 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ ( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? progressTracking.headline_setting.font_size : ''}px; line-height: ${( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? parseInt(progressTracking.headline_setting.font_size) + 20 : ''}px; font-weight: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_weight}; font-family: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_family}; color: ${progressTracking.headline_setting.font_color}; ${ progressTracking.headline_setting && progressTracking.headline_setting.has_shadow ? 'text-shadow: ' + progressTracking.headline_setting.shadow_x +'px ' + progressTracking.headline_setting.shadow_y + 'px ' + progressTracking.headline_setting.shadow_blur + 'px ' + progressTracking.headline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.headline }}</h4>
                    <h1 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_size : ''}px; line-height: ${ progressTracking.subheadline_setting ? (parseInt(progressTracking.subheadline_setting.font_size) + 20) : ''}px; font-weight: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_weight : ''}; font-family: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_family : ''}; color: ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.font_color ? progressTracking.subheadline_setting.font_color : ''}; ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.has_shadow ? 'text-shadow: ' + progressTracking.subheadline_setting.shadow_x +'px ' + progressTracking.subheadline_setting.shadow_y + 'px ' + progressTracking.subheadline_setting.shadow_blur + 'px ' + progressTracking.subheadline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.subheadline }}</h1>
                </div>
                <div class="banner_content" v-if="device == 'tab'">
                    <h4 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ ( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? progressTracking.headline_setting.font_size / 2 : ''}px; line-height: ${( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? (parseInt(progressTracking.headline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_weight}; font-family: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_family}; color: ${progressTracking.headline_setting.font_color}; ${ progressTracking.headline_setting && progressTracking.headline_setting.has_shadow ? 'text-shadow: ' + progressTracking.headline_setting.shadow_x +'px ' + progressTracking.headline_setting.shadow_y + 'px ' + progressTracking.headline_setting.shadow_blur + 'px ' + progressTracking.headline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.headline }}</h4>
                    <h1 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_size / 1.5 : ''}px; line-height: ${ progressTracking.subheadline_setting ? (parseInt(progressTracking.subheadline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_weight : ''}; font-family: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_family : ''}; color: ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.font_color  ? progressTracking.subheadline_setting.font_color : ''}; ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.has_shadow ? 'text-shadow: ' + progressTracking.subheadline_setting.shadow_x +'px ' + progressTracking.subheadline_setting.shadow_y + 'px ' + progressTracking.subheadline_setting.shadow_blur + 'px ' + progressTracking.subheadline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.subheadline }}</h1>
                </div>
                <div class="banner_content" v-if="device == 'cell'">
                    <h4 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ ( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? (progressTracking.headline_setting.font_size / 3 < 20 ? 20 : progressTracking.headline_setting.font_size / 3) : ''}px; line-height: ${( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? (progressTracking.headline_setting.font_size / 3 < 20 ? 26 : (parseInt(progressTracking.headline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_weight}; font-family: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_family}; color: ${progressTracking.headline_setting.font_color}; ${ progressTracking.headline_setting && progressTracking.headline_setting.has_shadow ? 'text-shadow: ' + progressTracking.headline_setting.shadow_x +'px ' + progressTracking.headline_setting.shadow_y + 'px ' + progressTracking.headline_setting.shadow_blur + 'px ' + progressTracking.headline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.headline }}</h4>
                    <h1 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ progressTracking.subheadline_setting ?( progressTracking.subheadline_setting.font_size / 3 < 11 ? 11 :  progressTracking.subheadline_setting.font_size / 3) : ''}px; line-height: ${ progressTracking.subheadline_setting ? ( progressTracking.subheadline_setting.font_size / 3 < 11 ? 16 : (parseInt(progressTracking.subheadline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_weight : ''}; font-family: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_family : ''}; color: ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.font_color  ? progressTracking.subheadline_setting.font_color : ''}; ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.has_shadow ? 'text-shadow: ' + progressTracking.subheadline_setting.shadow_x +'px ' + progressTracking.subheadline_setting.shadow_y + 'px ' + progressTracking.subheadline_setting.shadow_blur + 'px ' + progressTracking.subheadline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.subheadline }}</h1>
                </div>
            </div>
        </div>
        <div class="form_area_video">
            <div class="section_item form_video" :class="device == 'cell' ? 'mt-3' : 'mb-3'" ref="expvidsection" v-if="progressTracking.public_settings.display_explainer_video && ((progressTracking.public_settings.explainer_video && progressTracking.public_settings.explainer_video_type == 0) || (progressTracking.public_settings.explainer_embed && progressTracking.public_settings.explainer_video_type == 1))">
                <div class="video_wpr">
                    <div v-if="progressTracking.public_settings.explainer_video_type == 0" v-html="parseEmbedCode(progressTracking.public_settings.explainer_video)"></div>
                    <div v-if="progressTracking.public_settings.explainer_video_type == 1" v-html="progressTracking.public_settings.explainer_embed"></div>
                </div>
            </div>
            <!-- <div class="content_area tracker_container mt-3"> -->
            <div class="tracking_wpr">
                <div class="progress_title pt-5" v-if="progressTracking.data_settings.has_progress_question_label">
                    <h1>{{ progressTracking.data_settings.progress_question_label }}</h1>
                </div>
                <div class="pt-1" @click="handleSetting('formsetting')" id="assesment-question-panel">
                    <div class="group_section">
                        <div class="category_label">
                            <span>Body Composition</span>
                        </div>
                        <div class="water_card" v-if="progressTracking.data_settings && progressTracking.data_settings.has_weight">
                            <div class="top_area">
                                <img src="@/assets/images/weight.svg" alt="weight">
                                <div class="main_title">Weight</div>
                                <div class="sub_text">What is your weight today?</div>
                                <div class="result">
                                    {{ responses.weight }} <span>{{ progressTracking.data_settings ? shortUnit(progressTracking.data_settings.weight_unit) : 'lbs' }}</span>
                                </div>
                            </div>
                            <div class="bottom_area">
                                <div class="input_section">
                                    <div class="field_box">
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 180" v-model="responses.weight">
                                            <span class="unit">{{ progressTracking.data_settings ? shortUnit(progressTracking.data_settings.weight_unit) : 'lbs' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="water_card" v-if="progressTracking.data_settings && progressTracking.data_settings.has_body_fat">
                            <div class="top_area">
                                <img src="@/assets/images/body_fat.svg" alt="weight">
                                <div class="main_title">Body Fat</div>
                                <div class="sub_text">What is your body fat percentage today?</div>
                                <div class="result">
                                    {{ responses.body_fat }} <span>%</span>
                                </div>
                            </div>
                            <div class="bottom_area">
                                <div class="input_section">
                                    <div class="field_box">
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 21" v-model="responses.body_fat">
                                            <span class="unit">%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="water_card" v-if="progressTracking.data_settings && progressTracking.data_settings.has_circumference">
                            <div class="top_area">
                                <img src="@/assets/images/measurement.svg" alt="weight">
                                <div class="main_title">Circumference</div>
                                <div class="sub_text">What are your circumference measurements today?</div>
                            </div>
                            <div class="bottom_area">
                                <div class="input_section">
                                    <div class="field_box" v-if="progressTracking.data_settings && progressTracking.data_settings.neck">
                                        <label>Neck</label>
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 37">
                                            <span class="unit">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                    </div>
                                    <div class="field_box" v-if="progressTracking.data_settings && progressTracking.data_settings.shoulders">
                                        <label>Shoulders</label>
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 37">
                                            <span class="unit">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                    </div>
                                    <div class="field_box" v-if="progressTracking.data_settings && progressTracking.data_settings.biceps">
                                        <label>Biceps</label>
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 37">
                                            <span class="unit">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                    </div>
                                    <div class="field_box" v-if="progressTracking.data_settings && progressTracking.data_settings.chest">
                                        <label>Chest</label>
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 40">
                                            <span class="unit">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                    </div>
                                    <div class="field_box" v-if="progressTracking.data_settings && progressTracking.data_settings.belly_button">
                                        <label>Belly Button</label>
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 40">
                                            <span class="unit">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                    </div>
                                    <div class="field_box" v-if="progressTracking.data_settings && progressTracking.data_settings.waist">
                                        <label>Waist</label>
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 33">
                                            <span class="unit">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                    </div>
                                    <div class="field_box" v-if="progressTracking.data_settings && progressTracking.data_settings.hips">
                                        <label>Hips</label>
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 34">
                                            <span class="unit">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                    </div>
                                    <div class="field_box" v-if="progressTracking.data_settings && progressTracking.data_settings.thigh">
                                        <label>Thigh</label>
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 34">
                                            <span class="unit">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                    </div>
                                    <div class="field_box" v-if="progressTracking.data_settings && progressTracking.data_settings.calf">
                                        <label>Calf</label>
                                        <div class="field_area">
                                            <input type="number" placeholder="ex: 34">
                                            <span class="unit">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="water_card" v-if="progressTracking.data_settings && progressTracking.data_settings.has_progress_pics">
                            <div class="top_area">
                                <img src="@/assets/images/selfie.svg" alt="weight">
                                <div class="main_title">Client Tracking Pictures</div>
                                <div class="sub_text">Are you submitting client tracking pics with this report?</div>
                            </div>
                            <div class="bottom_area">
                                <div class="grp_wpr">
                                    <label for="spr" class="yes">
                                        <input type="radio" :value="1" id="spr" v-model="spr" class="mr-2" hidden>
                                        <div class="box_item"><i class="fas fa-thumbs-up"></i>Yes</div>
                                    </label>
                                    <label for="no_spr" class="no">
                                        <input type="radio" :value="0" id="no_spr" v-model="spr" class="mr-2" hidden>
                                        <div class="box_item"><i class="fas fa-thumbs-down"></i>No</div>
                                    </label>
                                </div>
                                <div class="picture_section" :class="progressTracking.data_settings.photo_orientation" v-if="spr">
                                    <div class="input_section">
                                        <div class="field_box">
                                            <label>Captions</label>
                                            <div class="field_area" v-show="progressTracking.data_settings.photo_view.front || progressTracking.data_settings.photo_view.side || progressTracking.data_settings.photo_view.back">
                                                <textarea name="" id="" cols="30" rows="5" placeholder="I'm down 15lbs already.."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="upload_image" v-show="progressTracking.data_settings.photo_view.front">
                                        <h5>Front Client Tracking Picture</h5>
                                        <image-upload :image-type="progressTracking.data_settings.photo_orientation" upload-text="Image" v-model="logo['front']"  :is-avatar="false" />
                                    </div>
                                    <div class="upload_image" v-show="progressTracking.data_settings.photo_view.side">
                                        <h5>Side Client Tracking Picture</h5>
                                        <image-upload :image-type="progressTracking.data_settings.photo_orientation" upload-text="Image" v-model="logo['side']"  :is-avatar="false" />
                                    </div>
                                    <div class="upload_image" v-show="progressTracking.data_settings.photo_view.back">
                                        <h5>Back Client Tracking Picture</h5>
                                        <image-upload :image-type="progressTracking.data_settings.photo_orientation" upload-text="Image" v-model="logo['back']"  :is-avatar="false" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="progressTracking.data_settings && progressTracking.data_settings.has_custom_questions && questionSections.length" >
                        <div class="group_section" v-for="(section, s) in questionSections" :key="s">
                            <div v-if="section.enabled && section.questions.length">
                                <div class="category_label">
                                    <span>{{ section.title }}</span>
                                </div>
                                <div class="water_card" v-for="(question, q) in section.questions" :key="q">
                                    <div class="top_area">
                                        <div class="main_title">{{q + 1}}</div>
                                        <div class="sub_text" v-html="question.title"></div>
                                    </div>
                                    <div class="bottom_area">
                                        <div class="input_section">
                                            <div class="field_box">
                                                <div class="left_spacing" v-if="question.type == 'checkbox'">
                                                    <div class="question-options">
                                                        <label :for="`form_styled_radio1-${option.id}`" class="checkbox" v-for="(option, o) in question.options" :key="o">
                                                            <input type="checkbox" value="1" :id="`form_styled_radio1-${option.id}`" name="is_first" class="mr-2" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                            <p>{{ option.title }}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="left_spacing" v-if="question.type == 'radio'">
                                                    <div class="question-options">
                                                        <label :for="`form_styled_radio1-${option.id}`" class="radio_opt" v-for="(option, o) in question.options" :key="o">
                                                            <input type="radio" value="1" :id="`form_styled_radio1-${option.id}`" name="is_first" class="mr-2" hidden>
                                                            <div class="radio_box">
                                                                <span class="dot"></span>
                                                            </div>
                                                            <p>{{ option.title }}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div v-if="question.type == 'true-false'">
                                                    <div class="grp_wpr">
                                                        <label :for="`form_styled_radio1-${option.id}`" v-for="(option, o) in question.options" :key="o" :class="option.title === 'True' ? 'yes' : 'no'">
                                                            <input type="radio" value="1" :id="`form_styled_radio1-${option.id}`" name="is_first" class="mr-2" hidden>
                                                            <div class="box_item"><i :class="`fas fa-thumbs-${option.title == 'True' ? 'up' : 'down'} mr-2`"></i>{{option.title == 'True' ? 'Yes' : 'No'}}</div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="left_spacing" v-if="question.type == 'text'">
                                                    <div class="field_area">
                                                        <input type="text">
                                                    </div>
                                                </div>
                                                <div class="upload_image" v-if="question.type == 'file'">
                                                    <image-upload image-type="all" upload-text="Image" v-model="logo[question.id]"  :is-avatar="false" />
                                                </div>
                                                <div class="left_spacing" v-if="question.type == 'textarea'">
                                                    <div class="field_area">
                                                        <textarea cols="30" rows="10"></textarea>
                                                    </div>
                                                </div>
                                                <div class="left_spacing" v-if="question.type == 'assessment'">
                                                    <div v-if="question.unit == 'BP' || question.unit == 'bp'">
                                                        <div class="field_area has_suffix">
                                                            <input type="text" placeholder="080/120">
                                                            <span class="suffix">{{ question.unit }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="['Pain Index', 'Points', 'Rating', 'Stars'].includes(question.unit)">
                                                        <div class="field_area has_suffix">
                                                            <input type="number" name="number" min="1" max="10" step="1">
                                                            <span class="suffix">{{ question.unit }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-else class="field_area has_suffix">
                                                        <input type="number" name="number">
                                                        <span class="suffix">{{ question.unit }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="form_area">
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Member ID</label>
                                    <div class="field_wpr m_id_field">
                                        <input type="text" placeholder="ID goes here.." >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div @click="handleSetting('subbtnsetting')" ref="subbtnsection">
                        <button type="button" class="primary_btn" :style="`background-color: ${ progressTracking.public_settings.submit_btn_bgcolor ? progressTracking.public_settings.submit_btn_bgcolor : '' }; color: ${ progressTracking.public_settings.submit_btn_textcolor ? progressTracking.public_settings.submit_btn_textcolor : '' };`">{{ progressTracking.public_settings.submit_btn_text ? progressTracking.public_settings.submit_btn_text : 'Submit' }}</button>
                    </div>
                </div>
            </div>
        </div>
        <footer class="dashboard_footer" v-show="progressTracking.public_settings.display_footer == 1" @click="handleSetting('ftrsetting')" ref="ftrsection" :style="`background: ${progressTracking.public_settings.footer_bgcolor};`">
            <ul>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_facebook"><a :href="progressTracking.public_settings.footer_facebook" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_twitter"><a :href="progressTracking.public_settings.footer_twitter" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_instagram"><a :href="progressTracking.public_settings.footer_instagram" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_linkedin"><a :href="progressTracking.public_settings.footer_linkedin" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_youtube"><a :href="progressTracking.public_settings.footer_youtube" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
            </ul>
            <h4 :style="`color: ${progressTracking.public_settings.footer_textcolor};`">{{ progressTracking.public_settings.footer_company ?  progressTracking.public_settings.footer_company : `© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}` }}</h4>
            <p>
                <a :href="progressTracking.public_settings.footer_terms" :style="`color: ${progressTracking.public_settings.footer_textcolor};`">Terms</a>
                <a :href="progressTracking.public_settings.footer_policy" :style="`color: ${progressTracking.public_settings.footer_textcolor};`">Privacy</a>
                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                    <a v-if="whiteLabel.has_link == 1" :style="`color: ${progressTracking.public_settings.footer_textcolor};`" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                    <a v-else :style="`color: ${progressTracking.public_settings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                </template>
                <a v-else :style="`color: ${progressTracking.public_settings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
            </p>
        </footer>
    </div>
</template>
<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState } from 'vuex'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))

    import VideoParser from '@/utils/VideoParser'

    export default {
        name: 'Progress Tracking Preview Content',

        data () {
            return {
                accountDrop: false,
                logo: [],
                weekdays: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday'
                ],

                spr: 'yes',
                fpr: 0,
                whiteLabel: {},
                responses: {
                    weight: 0,
                    body_fat: 0,
                }
            }
        },

        props: {
            progressTracking: Object,
            device: {
                type: String,
                default: 'cell'
            },
            isNew: Boolean,
        },

        emit: [ 'update:modelValue' ],

        watch: {
            'progressTracking.data_settings': {
                handler: function () {
                    const vm = this;

                    vm.toggleAssesmentQuestion();
                },
                deep: true,
            }
        },

        components: {
            ImageUpload
        },

        computed: mapState({
            user: state => state.authModule.user,
            questionSections: state => state.progressTrackingModule.questionSections,
            questionLoader: state => state.progressTrackingModule.progressTrackingQuestionLoader,
            membership: state => state.authModule.membership,
        }),

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};

            vm.toggleAssesmentQuestion();
        },

        methods:{
            accFunc (e) {
                let el = e.currentTarget.closest('.quest_wpr');
                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    el.classList.add('active');
                }
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            goTo (refName) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    setTimeout(function() {
                        if (element) {
                            let top = element.offsetTop;
                            document.querySelector('.preview_content .content_area').scrollTo(0, top);
                        }
                    }, 100);
                }, 10);
            },

            handleSetting(val) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                if (vm.$parent.$parent && vm.$parent.$parent.setting) {
                    vm.$parent.$parent.setting(val);
                } else {
                    vm.$parent.setting(val);
                }
            },

            getQuestionIndex (type) {
                const vm = this;
                let questionIndex       = 1;
                const fpr               = vm.fpr ? 0 : 1;
                const weekEnabled       = vm.progressTracking.data_settings.week_enabled ? 1 : 0;
                const hasWeight         = vm.progressTracking.data_settings.has_weight ? 1 : 0;
                const hasBodyFat        = vm.progressTracking.data_settings.has_body_fat ? 1 : 0;
                const hasCircumference  = vm.progressTracking.data_settings.has_circumference ? 1 : 0;
                const hasProgressPics   = vm.progressTracking.data_settings.has_progress_pics ? 1 : 0;

                questionIndex += fpr + weekEnabled;

                if (type == 'weight') {
                    return questionIndex;
                } else if (type == 'fat') {
                    questionIndex += hasWeight;
                } else if (type == 'circumference') {
                    questionIndex += hasWeight + hasBodyFat;
                } else if (type == 'pics') {
                    questionIndex += hasWeight + hasBodyFat + hasCircumference;
                }

                return questionIndex;
            },

            toggleAssesmentQuestion () {
                setTimeout(() => {
                    const el = document.getElementById('assesment-question-panel');

                    if (el && !el.innerText) {
                        el.style.display = 'none';
                    } else {
                        el.style.display = 'block';
                    }
                }, 500);
            },

            openNotes (ev) {
                let showElm = ev.currentTarget.closest('.notes');

                if (showElm.classList.contains('active')) {
                    showElm.classList.remove('active');
                } else {
                    showElm.classList.add('active');
                }
            },

            shortUnit (unit) {
                const units = {
                    kilograms: 'kg',
                    Kilograms: 'kg',
                    pounds: 'lbs',
                    Pounds: 'lbs',
                };

                return units[unit] ? units[unit] : unit;
            }
        }
    }
</script>

<style scoped>
.tab .dashboard_content {
    margin: 0;
}

.cell .dashboard_content {
    margin: 0;
    padding: 0 15px;
}

.client_card {
    width: 100%;
    border: 0;
}

.client_card .card_body h1 {
    font-size: 60px;
    line-height: 75px;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 15px;
}

.tab .client_card .card_body h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
}

.client_card .card_body h4 {
    font-size: 28px;
    line-height: 38px;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 0;
    font-weight: 500;
}

.tab .client_card .card_body h4 {
    font-size: 18px;
    line-height: 25px;
}

.dashboard_footer {
    width: 100%;
    text-align: center;
}

.goal_btn {
    background: #f2a31d;
    padding: 12px 15px;
    font-size: 17px;
    line-height: 22px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    border-radius: 8px;
    text-align: center;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.goal_btn i {
    margin-right: 15px;
    color: #32373b;
    font-size: 22px;
}

.cell .dashboard {
    margin: 0;
}

.cell .client_card {
    border-radius: 6px;
}

.cell .client_card .card_header {
    padding: 15px 20px;
}

.cell .client_card .card_header .user_box>img {
    width: 20px;
    height: 20px;
}

.cell .client_card .card_header .user_box h5 {
    font-size: 11px;
    line-height: 14px;
}

.cell .client_card .card_body {
    text-align: center;
}

.cell .client_card .card_body .banner_content {
    padding: 0 15px;
}

.cell .client_card .card_body h1 {
    font-size: 17px;
    line-height: 30px;
    margin: 0;
    font-weight: 600;
    color: #fff;
    position: relative;
    z-index: 3;
}

.cell .client_card .card_body h4 {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #fff;
    text-transform: initial;
    position: relative;
    z-index: 3;
}

.cell .client_card .card_body .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
}

.cell .form_area {
    max-width: 400px;
    border-radius: 6px;
}

.cell .form_grp {
    padding: 15px 0;
}

.cell .grp_wpr {
    display: flex;
    margin: 0 -7px;
}

.cell .box_item {
    flex: 1 0 50%;
    padding: 2px 7px;
}

.cell .setting_wpr .group_item .input_label {
    font-size: 13px;
    line-height: 17px;
}

.cell .grp_wpr label {
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
}

.cell .grp_wpr label h5 {
    font-size: 13px;
    font-weight: 500;
}

.cell .field_wpr textarea {
    height: 52px;
    font-size: 11px;
    padding: 5px 10px;
}

.cell .field_wpr.has_suffix .suffix {
    font-size: 11px;
}

.cell .require_wpr h4 {
    font-size: 12px;
    line-height: 15px;
}

.cell .require_wpr p {
    font-size: 10px;
    line-height: 13px;
}

.cell h3.sub_header {
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0 10px;
}

.cell .day_sorting li {
    min-width: 75px;
}

.cell .day_sorting li label {
    padding: 5px 10px;
}

.cell .day_sorting li label p {
    font-size: 10px;
}

.cell .goal_btn {
    padding: 7px 10px;
    font-size: 11px;
    line-height: 14px;
    border-radius: 4px;
}

.cell .goal_btn i {
    font-size: 14px;
}

.cell .day_sorting li label span {
    transform: scale(0.8);
}

.cell .upload_image label {
    padding: 10px;
}

.cell .upload_image .icon {
    max-height: 40px;
    width: auto;
}

.cell .upload_image h4 {
    font-size: 10px;
    line-height: 12px;
    margin-top: 7px;
}


:deep(.video_wpr) {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
}

:deep(.video_wpr iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

:deep(.tab_content) {
    height: 100%;
}

.quest_panel {
    margin-bottom: 40px;
}

.dashboard_content h3.category_title {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 15px;
}

.quest_wpr {
    border-radius: 6px;
    margin-bottom: 25px;
    background: #fff;
}
.progress_details {
    padding: 20px;
    position: relative;
}

.progress_details:before {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 30px;
    background: url('~@/assets/images/curve_bg.svg');
    background-size: cover;
}

.progress_details .field_box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.sec_wpr {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px 10px;
}

.progress_details .sec_wpr label {
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 400;
    text-align: left;
    padding: 10px 0 7px 0;
}

.progress_details .field_area {
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
}

.progress_details .field_area.has_suffix .suffix {
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 40px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}

.progress_details .field_area input {
    height: 40px;
    width: 100%;
    padding: 0 15px;
    font-size: 11px;
    color: #5a5a5a;
    background: transparent;
}

.progress_details .field_area input[type=number]::-webkit-inner-spin-button,
.progress_details .field_area input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.progress_details .field_box .suffix {
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 40px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}

.progress_details .field_area textarea {
    height: 80px;
    width: 100%;
    padding: 10px;
    font-size: 11px;
    color: #5a5a5a;
    border-radius: 4px;
    background: transparent;
    resize: none;
}

.grp_wpr {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 5px 0;
}

.grp_wpr label .box_item {
    font-size: 13px;
    line-height: 15px;
    border-radius: 4px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* opacity: 0.5; */
    transition: all 0.3s ease-in-out;
}

.grp_wpr label .box_item i {
    font-size: 13px;
    margin-right: 7px;
}

/* .grp_wpr label.yes .box_item {
    border: 1px solid #b2eabf;
    background: #effff2;
    color: #23993e;
}

.grp_wpr label.no .box_item {
    border: 1px solid #ffb4b4;
    background: #ffecec;
    color: #eb1414;
} */

.grp_wpr label.yes .box_item {
    border: 1px solid var(--var-card-elm-fade-color);
    color: var(--var-card-elm-color);
}

.grp_wpr label.no .box_item {
    border: 1px solid #d9d9d9;
    color: #777;
}
.grp_wpr label input[type="radio"]:checked~.box_item {
    opacity: 1;
}

.picture_section {
    border-top: 1px dashed #E4E7EC;
    padding: 5px 0;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upload_image {
    margin: 0 0 10px 0;
    padding: 0 10px;
}

.quest_wpr h6 {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
    margin-bottom: 5px;
}

.upload_image h5 {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 400;
    text-align: left;
    padding-bottom: 7px;
}

.picture_section.Portrait .upload_image h5 {
    max-width: 200px;
    margin: 0 auto;
}

.picture_section.Landscape .upload_image :deep(.upload_wpr) {
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
    border-radius: 8px;
}

.picture_section.Portrait .upload_image :deep(.upload_wpr) {
    width: 200px;
    min-height: 300px;
    margin: 0 auto;
    padding: 0;
    border-radius: 8px;
}
.picture_section .upload_image :deep(.upload_wpr .upload_area) {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    padding: 0;
    background: #f5f5f5;
}

.upload_image :deep(.upload_wpr .upload_area img:not(.uploaded_img)) {
    max-width: 50px;
}

.upload_image :deep(.upload_wpr .upload_area h4) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #5a5a5a;
}

.upload_image :deep(.upload_wpr .info) {
    margin: 7px 0 !important;
    font-size: 11px;
    line-height: 14px;
    ;
}

.form_grp {
    padding: 10px 0;
}

.field_wpr {
    background: #fff;
}

.field_wpr input {
    height: 45px;
    font-size: 13px;
}

.primary_btn {
    height: 55px;
    letter-spacing: 1px;
    font-size: 16px;
    margin: 30px 0 40px 0;
    text-transform: uppercase;
    cursor: pointer;
}

.opt_item {
    flex: 0 0 30%;
    padding: 5px 15px;
}

.cell .opt_item {
    flex: auto;
    padding: 0px 2px;
}

.opt_item.Week_btn {
    flex-basis: 100%;
}

.success_goal_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    border-radius: 6px;
    background: #2F7FED;
    color: #fff;
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    width: 100%;
    border: 0;
}

.form_grp .opt_item input[type='checkbox'] {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    outline: none;
    border: 2px solid var(--var-element-color);
}

.form_grp .opt_item input[type='checkbox']:before {
    content: '';
    display: block;
    width: 80%;
    height: 80%;
    margin: 10%;
    border-radius: 50%;
}

.form_grp .opt_item input[type="checkbox"]:checked:before {
    background: var(--var-element-color);
}

.form_grp .opt_item input[type="checkbox"]:checked {
    border-color: var(--var-element-color);
}

.logo_img {
    max-width: 100%;
    height: auto;
    max-height: 25px;
}

.client_card .card_header .user_box {
    margin-left: auto;
}


.cell .form_area_video {
    max-width: 800px;
    margin: 0px;
    flex: 1;
}
.cell .form_video{
    padding: 30px 20px 0 20px;
}

.form_area_video {
    max-width: 800px;
    margin: 20px auto 50px auto;
    flex: 1;
}

.grp_wpr.grp_option {
    display: flex;
    flex-wrap: wrap;
}

.cell .dashboard_content .field_wpr :deep(textarea) {
    height: 45px;
    font-size: 10px;
    line-height: 12px;
    padding: 5px 10px;
}

:deep(.cell .tab_content .upload_wpr .info) {
    text-align: center;
}

:deep(.cell .tab_content .upload_wpr img) {
    max-width: 70%;
    height: auto;
    font-size: 9px;
    line-height: 15px;
    color: #747474;
    font-weight: 500;
    margin: 10px 0;
}

:deep(.cell .upload_area h4) {
    font-size: 9px;
    line-height: 15px;
    color: #575757;
    font-weight: 500;
    margin: 14px 0 0;
    display: block !important;
}

:deep(.cell .upload_wpr .upload_area) {
    height: 110px;
    min-height: 80px;
}

.field_wpr.has_suffix {
    padding-right: 100px;
}

.field_wpr.has_suffix .suffix {
    width: 100px;
}

.picture_section .upload_wpr .upload_area {
    border-radius: 0;
}

.picture_section :deep(.upload_wpr img) {
    height: 25px;
    width: auto;
    opacity: 0.5
}

.picture_section :deep(.upload_wpr h4) {
    font-size: 13px;
    line-height: 16px;
}

.picture_section :deep(.upload_wpr .info) {
    text-align: left;
}

.tab .picture_section :deep(.upload_wpr img) {
    width: 50px;
    height: auto;
}

.tab .picture_section :deep(.upload_wpr h4) {
    font-size: 11px;
    line-height: 15px;
}

.tab .picture_section :deep(.upload_wpr .info) {
    font-size: 10px;
    line-height: 12px;
}

.cell .picture_section>li {
    width: 100%;
}


.question-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 0;
    /* gap: 20px; */
}

.question-options label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
}

.question-options label p {
    font-size: 11px;
    line-height: 15px;
    padding-left: 10px;
}

.question-options .radio_box {
    width: 13px;
    height: 13px;
    border: 1px solid #5a5a5a;
    border-radius: 50%;
    position: relative;
    display: block;
}

.question-options .radio_box .dot {
    position: absolute;
    background: #5a5a5a;
    border-radius: 50%;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
}

.question-options label input[type="radio"]:checked~.radio_box .dot {
    transform: scale(1);
}

.question-options label.checkbox span {
    border: 1px solid #5a5a5a;
}

.question-options label.checkbox span i {
    color: #5a5a5a;
}

.progress_details .notes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-height: 25px;
    overflow: hidden;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
}

.progress_details .notes.active {
    max-height: 500px;
}

.progress_details .notes .add_notes {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    cursor: pointer;
}

.progress_details .notes .add_notes i {
    margin-top: 2px;
    font-size: 8px;
}

.progress_details .notes textarea {
    height: 80px;
    width: 100%;
    padding: 10px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #121525;
    resize: vertical;
}

.progress_details .notes .save_btn {
    padding: 4px 10px;
    border-radius: 3px;
    background: var(--var-card-elm-color);
    color: #fff;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    margin-left: auto;
    cursor: pointer;
}

:deep(.progress_title h1) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 5px;
    text-align: center;
}

:deep(.progress_title h4) {
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    color: #5a5a5a;
    margin-bottom: 15px;
    text-align: center;
}

.category_label {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    position: relative;
}

.category_label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 15%;
    right: 15%;
    border-bottom: 1px solid #d9d9d9;
    z-index: 0;
}

.category_label span {
    padding: 7px 15px;
    background: #fafafb;
    position: relative;
    z-index: 1;
}

.submit_progress .field_wpr {
    background: #fff;
}

.submit_progress .field_wpr input {
    height: 45px;
}

.submit_progress .primary_btn {
    height: 45px;
    font-size: 15px;
    line-height: 20px;
}

/* Range Slider */
.cell .tracker_container {
    background: #fbfbfb;
    padding: 0 20px 30px 20px;
    height: 450px;
}

.water_card {
    background: #fff;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 30px;
}

.top_area {
    padding: 20px 20px 30px 20px;
    background: var(--var-card-bg-color);
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom_area {
    padding: 20px 20px 10px 20px;
    position: relative;
}

.bottom_area:before {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 30px;
    background: url('~@/assets/images/curve_bg.svg');
    background-size: contain;
}

.water_card img {
    max-height: 100px;
    width: auto;
    display: block;
    margin: 0 auto 10px auto;
}

.water_card .main_title {
    font-size: 18px;
    line-height: 25px;
    color: var(--var-card-text-color);
    font-weight: 500;
    margin-bottom: 10px;
}

.water_card .sub_text {
    font-size: 13px;
    line-height: 20px;
    color: var(--var-card-text-color);
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
}

.water_card .result {
    font-size: 20px;
    line-height: 23px;
    color: var(--var-card-text-color);
    font-weight: 400;
    padding: 5px 0;
    /* border-bottom: 1px solid #5a5a5a; */
    margin-bottom: 15px;
}

.water_card .result span {
    font-size: 15px;
    line-height: 18px;
    color: var(--var-card-text-color);
    font-weight: 400;
}

.water_card .result_list {
    width: 100%;
    padding-bottom: 15px;
}

.water_card .result_list ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    row-gap: 10px;
}

.water_card .result_list li {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 30%;
}

.water_card .result_list li label {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}

.water_card .result_list li label span {
    background: var(--var-card-elm-color);
    padding: 3px 8px;
    font-size: 10px;
    line-height: 12px;
    border-radius: 10px;
    color: #fff;
    font-weight: 400;
}

.water_card .result_list li .item_result {
    font-size: 20px;
    line-height: 23px;
    color: #121525;
    font-weight: 400;
    padding: 5px 0;
    /* border-bottom: 1px solid #5a5a5a; */
}

.water_card .result_list li .item_result span {
    font-size: 15px;
    line-height: 18px;
    color: #121525;
    font-weight: 400;
}

.water_card .result_list.sm li .item_result {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 400;
}

.water_card .ratings {
    display: flex;
    gap: 4px;
    padding-bottom: 15px;
}

.water_card .ratings span {
    font-size: 12px;
    color: #999;
}

.water_card .ratings span i.active {
    color: var(--var-card-elm-color);
}

.water_card .single_item {
    padding: 5px 10px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
}

.water_card .single_item {
    padding: 5px 10px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    margin-bottom: 10px;
}

.water_card .multiple_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 5px;
    margin-bottom: 10px;
}

.water_card .multiple_items span {
    padding: 4px 8px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
}

.range_slider {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 0;
}

.range_slider .range_label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    margin: 15px 0 0;
}

.range_slider span {
    height: 6px;
    background: var(--var-card-elm-color);
    position: absolute;
    top: 15px;
    border-radius: 3px;
}

.range_slider span label {
    position: absolute;
    right: 0;
    transform: translateX(50%);
    bottom: 100%;
    padding: 3px 6px;
    background: var(--var-card-elm-color);
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 400;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    display: inline-block;
    margin-bottom: 12px;
}

.range_slider span label:after {
    position: absolute;
    content: '';
    border-top: 4px solid var(--var-card-elm-color);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.range_slider .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background: var(--var-card-elm-fade-color);
    outline: none;
    margin: 0;
    position: relative;
}

.range_slider .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid var(--var-card-elm-color);
    background: var(--var-card-elm-color);
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}

.range_slider .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border: 1px solid var(--var-card-elm-color);
    background: var(--var-card-elm-color);
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}

.water_card .input_section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.water_card .input_section .field_box {
    flex: 1 0 50%;
    padding: 0 10px 10px 10px;
    display: flex;
    flex-direction: column;
}

.water_card .input_section label {
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 400;
    text-align: left;
    padding: 10px 10px 7px 10px;
}

.water_card .input_section .field_box label {
    padding: 10px 0 7px 0;
}

.water_card .input_section .field_box .field_area {
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
}

.water_card .input_section input {
    height: 40px;
    width: 100%;
    padding: 0 10px;
    background: transparent;
}

.water_card .input_section .field_box .unit {
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 40px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}

.water_card .check_list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px;
}

.water_card .check_list li {
    width: 50%;
    padding: 5px;
}

.water_card .check_list li .checkbox {
    justify-content: flex-start;
}

.water_card .check_list li .checkbox span.checked i {
    opacity: 1;
    transform: scale(1);
}

.water_card .check_list li .checkbox p {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    padding-left: 10px;
}

.water_card .radio_list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -10px;
}

.water_card .radio_list li {
    width: 50%;
    padding: 10px;
}

.water_card .radio_list li .radio_box {
    border: 1px solid #e9e9e9;
    background: #fff;
    border-radius: 8px;
    padding: 5px 15px 15px 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.water_card .radio_list li .radio_box.active {
    border-color: var(--var-card-bg-color);
    background: var(--var-card-bg-color);
}

.water_card .radio_list li .radio_box img {
    height: 50px;
    width: auto;
    margin-bottom: 5px;
}

.water_card .radio_list li .radio_box h5 {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 5px;
}

.water_card .radio_list li .radio_box p {
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
}

.water_card .notes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-height: 25px;
    overflow: hidden;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
}

.water_card .notes.active {
    max-height: 500px;
}

.water_card .notes .add_notes {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    cursor: pointer;
}

.water_card .notes .add_notes i {
    margin-top: 2px;
    font-size: 8px;
}

.water_card .notes textarea {
    height: 80px;
    width: 100%;
    padding: 10px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #121525;
    resize: vertical;
}

.water_card .notes .save_btn {
    padding: 4px 10px;
    border-radius: 3px;
    background: var(--var-card-elm-color);
    color: #fff;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    margin-left: auto;
    cursor: pointer;
}

.water_card table.table_list {
    width: 100%;
    border-spacing: 1px;
    background: #eaeaea;
    margin-bottom: 15px;
}

.water_card table.table_list tr {
    border-bottom: 1px solid #e9e9e9;
}

.water_card table.table_list th,
.water_card table.table_list td {
    padding: 5px 10px;
    text-align: left;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}

.water_card table.table_list th {
    background: #fbfbfb;
}

.water_card table.table_list td {
    background: #fff;
}

.water_card table.table_list td:first-child {
    width: 37px;
}

.water_card table.table_list td:last-child {
    width: 120px;
}

.water_card table.table_list td .field_area {
    gap: 5px;
    display: flex;
    align-items: center;
    position: relative;
}

.water_card table.table_list td input {
    height: 25px;
    width: 100%;
    background: transparent;
}

.water_card table.table_list td .unit svg {
    width: 18px;
    height: 20px;
}

.water_card table.table_list .checkbox span.checked i {
    opacity: 1;
    transform: scale(1);
}

.water_card .body_area {
    margin-bottom: 20px;
    text-align: left;
}

.water_card .body_area table.table_list td:last-child {
    width: 100px;
}

.water_card .body_area table.table_list .field_area.blur {
    opacity: 0.5;
    pointer-events: none;
}

.water_card .pain_area .tabs {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #e9e9e9;
}

.water_card .pain_area .tabs li {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #121525;
    padding: 0 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-bottom: -1px;
}

.water_card .pain_area .tabs li.active {
    color: var(--var-card-elm-color);
}

.water_card .pain_area .area_section {
    padding-top: 15px;
}

.water_card .pain_area .area_section .pain_item {
    padding: 10px 15px;
    background: #fbfbfb;
    border-radius: 5px;
    margin-bottom: 15px;
}

.water_card .pain_area .area_section .pain_item label.checkbox {
    justify-content: flex-start;
}

label.checkbox span.checked i {
    transform: scale(1);
    opacity: 1;
}

.water_card .pain_area .area_section .pain_item label.checkbox p {
    font-size: 13px;
    line-height: 16px;
    padding-left: 10px;
}

.water_card .pain_area .area_section .pain_item .select_box {
    width: 100%;
    border: 1px solid #e9e9e9;
    background: #fff;
    border-radius: 5px;
    padding: 7px 20px 7px 10px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
    margin: 15px 0 25px 0;
    text-align: left;
    position: relative;
}

.water_card .pain_area .area_section .pain_item .select_box p {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #777;
}

.water_card .pain_area .area_section .pain_item .select_box .tag_wpr {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
}

.water_card .pain_area .area_section .pain_item .select_box .tag {
    background: var(--var-card-elm-color);
    padding: 2px 6px;
    font-size: 10px;
    line-height: 12px;
    border-radius: 3px;
    color: #fff;
    font-weight: 400;
}

.water_card .pain_area .area_section .pain_item .select_box>i {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #999;
}

.water_card .pain_area .area_section .pain_item .select_box .dropdown_wpr {
    z-index: 4;
}

.select_box .dropdown_wpr ul li {
    display: flex;
    gap: 10px;
}

.select_box .dropdown_wpr ul li:last-child {
    border: 0;
    justify-content: flex-start;
}

.select_box .dropdown_wpr ul li .checkbox {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    border: 1px solid #BABDC3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
    padding: 0 !important;
}

.select_box .dropdown_wpr ul li .checkbox i {
    font-size: 8px;
    line-height: 10px;
    color: #121525;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.select_box .dropdown_wpr ul li .checkbox.checked i {
    transform: scale(1);
    opacity: 1;
}

.water_card .pain_area .area_section .pain_item .result_info {
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
    padding: 10px 15px;
    border-radius: 5px;
    background: var(--var-card-bg-color);
    margin: 10px 0;
}

.notification-warning {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #F2A31D;
    margin-top: -5px;
}

/* ------- Custom Tracking ----------- */

.empty_items {
    padding: 30px 15px;
    border: 1px solid #f5f5f5;
    background: #fafafa;
    border-radius: 6px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 30px;
    font-size: 13px;
    line-height: 18px;
    color: #999;
    font-weight: 400;
    text-align: center;
}

.custom_btn {
    color: #5a5a5a;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    border: 1px solid #e9e9e9;
    border-radius: 20px;
    margin: 20px auto 5px auto;
    cursor: pointer;
}

.custom_btn i {
    font-size: 11px;
    margin-right: 5px;
}

.tracking_wpr {
    /* border-top: 1px solid #e9e9e9; */
    padding: 10px 20px 30px 20px;
}
.tracking_wpr .field_wpr.m_id_field{
    background: #e7e7e7;
    border: 0;
}

.question_wpr {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    margin-top: 20px;
}

.question_wpr .section_title {
    padding: 20px 30px;
    background: #fbfbfb;
    border-radius: 6px;
}

.question_wpr .section_title .delete_btn {
    color: #eb1414;
    font-size: 11px;
    line-height: 16px;
    margin: 0 0 0 15px;
    cursor: pointer;
}

.global_setting .setting_wpr .form_grp {
    padding: 5px 0;
}

.global_setting .setting_wpr .form_grp .group_item {
    padding: 10px 5px;
}

.progress_title h1 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 5px;
    text-align: center;
}

.progress_title h4 {
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    color: #5a5a5a;
    margin-bottom: 15px;
    text-align: center;
}

.category_label {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    position: relative;
}

.category_label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 15%;
    right: 15%;
    border-bottom: 1px solid #d9d9d9;
    z-index: 0;
}

.category_label span {
    padding: 7px 15px;
    background: #fafafb;
    position: relative;
    z-index: 1;
}

.submit_progress .field_wpr {
    background: #fff;
}

.submit_progress .field_wpr input {
    height: 45px;
}

.submit_progress .primary_btn {
    height: 45px;
    font-size: 15px;
    line-height: 20px;
}
.field_area.has_suffix .suffix {
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 40px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}
.water_card .check_list li .checkbox {
    padding: 0;
}

.water_card .input_section .field_box .field_area textarea {
    padding: 10px;
    width: 100%;
    resize: none;
    border-radius: 5px;
}

@media(max-width: 1199px) {
    .info_bar {
        margin: 20px 10px;
    }

    .cover_type {
        margin: 15px -25px;
    }
}

@media(max-width: 991px) {
    .prog_edit {
        padding: 20px;
    }
}

@media(max-width: 767px) {

    .category_bar input,
    .category_bar .quest_title {
        font-size: 13px;
    }

    .checkbox_list li {
        width: 33.333%;
    }

    .circumference_list li {
        flex: 0 0 100%;
    }
}

@media(max-width: 599px) {
    .checkbox_list li label.checkbox p {
        font-size: 11px;
        line-height: 16px;
    }
}

@media(max-width: 499px) {
    .day_type li .day_wpr h4 {
        font-size: 11px;
    }

    .sqr_input {
        width: 20px;
        height: 20px;
        font-size: 10px;
    }

    :deep(.dp__main .dp__input) {
        font-size: 10px;
        padding: 0 0 0 25px !important;
    }

    :deep(.dp__main .dp__input_icons) {
        width: 12px;
    }

    .day_type li .day_wpr label {
        width: 40px;
        min-width: auto;
        flex-shrink: 0;
    }

    .time_listing li {
        width: 25%;
    }

    .circumference_list li {
        width: 100%;
    }

    .custom_list li {
        padding: 15px;
    }

    .custom_list li .section_wpr {
        padding: 10px 20px;
    }

    .category_bar .capsule_btn {
        padding: 0 10px 0 0;
    }

    .category_bar .add_btn {
        margin: 0 0 0 10px;
        padding-right: 10px;
    }

    .sub_categories {
        padding: 20px 15px;
    }

    .prog_edit {
        padding: 15px;
    }

    .cover_type {
        margin: 15px -15px;
    }

    .global_setting .color_container,
    .question_wpr .section_title {
        padding: 20px;
    }

    .tracking_wpr {
        padding: 10px 20px 30px 20px;
    }

    .global_setting .setting_wpr .form_grp .group_item {
        flex: 0 0 100%;
    }

    .scope_area .field_wpr .unit {
        height: 40px;
        font-size: 11px;
    }

    .checkbox_list li {
        width: 50%;
    }
}
</style>
